.BackIcon{
	cursor: pointer;

	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}
}

.ArrowIcon{
	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}
}

.BreadcrumbsLink{
	&, & > *{
		color: var(--sidebar-primary-text-color);
	}
}

.BreadcrumbsText{
	color: var(--tertiary-text-color);
}