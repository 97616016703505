/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

*, *:before, *:after {
    box-sizing: border-box;
}

ul, ol { margin: 0; padding: 0; list-style-position: inside; }

html {
    padding: 0;
    margin: 0;
    height: 100%;
    font-size: var(--text-size-x3);
    line-height: var(--text-line-height-x3);
    overflow: hidden;
    user-select: none;
}

body {
    padding: 0;
    margin: 0;
    font-weight: 400;
    height: 100%;
    background-color: var(--secondary-bg-color);
    overflow: hidden;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    color: var(--primary-text-color);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: var(--alternative-secondary-bg-color);
    border-radius: 10px;
}
   
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--alternative-primary-color);
}

#root {
    height: 100%;
    overflow: hidden;
}


:root {
    /* Text Sizes */
    --text-size-x1: 10px;
    --text-size-x2: 12px;
    --text-size-x3: 14px;
    --text-size-x4: 16px;
    --text-size-x5: 18px;
    --text-size-x6: 20px;
    --text-size-x7: 40px;
    --text-size-x8: 60px;

    /* Text Line Heights */
    --text-line-height-x1: 14px;
    --text-line-height-x2: 16px;
    --text-line-height-x3: 19px;
    --text-line-height-x4: 18px;
    --text-line-height-x5: 25px;
    --text-line-height-x6: 30px;
    --text-line-height-x7: 60px;
    --text-line-height-x8: 70px;
}

/* Theme */
:root{
    /* Background Colors */
    --primary-bg-color: #ffffff;
    --secondary-bg-color: #F5F6F8;
    --dander-bg-color: #FCE6EA;
    --success-bg-color: #EAF6E7;
    --warning-bg-color: #FFF3E0;
    --disable-bg-color: #EDF0F4;
    --danger-bg-color: #FFE3E0;
    --neutral-bg-color: #EAECF5;

    /* Text Colors */
    --primary-text-color: #2A2E43;
    --secondary-text-color: #495079;
    --tertiary-text-color: #888CA5;
    --nodes-text-color: #0C1E2A;
    --disable-text-color: #CDD3DB;
    --danger-text-color: #D13B51;
    --success-text-color: #208835;
    --warning-text-color: #F18D5D;

    /* Icon Colors */
    --primary-bg-icons-color: #3B54D0;
    --secondary-bg-icons-color: #6773A3;
    --alternative-primary-icons-color: #FFFFFF;
    --alternative-secondary-icons-color: #8F8FC1;
    --success-icons-color: #208835;
    --warning-icons-color: #F18D5D;
    --danger-icons-color: #D13B51;
    --disable-icons-color: #CDD3DB;

    /* Alternative Colors */
    --alternative-primary-color: #6773A3;
    --alternative-secondary-bg-color: #D9E0E7;
    --alternative-primary-text-color: #FFFFFF;
    --alternative-secondary-text-color: #C6CAF8;
    --alternative-accent-text-color: #3B54D0;

    /*Sidebar Colors*/
    --sidebar-primary-color: #ffffff;
    --sidebar-hover-color: #EEEFF7;
    --sidebar-primary-text-color: #3B54D0;
    --sidebar-text-color: #6F767E;

    /* Button - Primary */
    --primary-button-bg-color: #3B54D0;
    --primary-button-text-color: #FFFFFF;
    --primary-button-hover-text-color: #ffffff;
    --primary-button-hover-color: #2D46BD;
    --primary-button-active-color: #273DA5;

    /* Button - Secondary */
    --secondary-button-bg-color: #ffffff;
    --secondary-button-border-color: #DEDDF2;
    --secondary-button-hover-color: #3D3BD0;
    --secondary-button-hover-text-color: #FFFFFF;
    --secondary-button-active-color: #2028AC;
    --secondary-button-text-color: #0C1E2A;

    /* Button - Ghost */
    --ghost-button-text-color: #3B54D0;
    --ghost-button-hover-color: #2D46BD;
    --ghost-button-active-color: #273DA5;

    /* Button - Danger */
    --danger-button-bg-color: #E40931;
    --danger-button-text-color: #FFFFFF;
    --danger-button-bg-hover-color: #C8062A;
    --danger-button-bg-active-color: #B20625;

    /* Button - Success */
    --success-button-bg-color: #208835;
    --success-button-text-color: #FFFFFF;
    --success-button-bg-hover-color: #19772C;
    --success-button-bg-active-color: #0D6B20;

    /* Button - Warning */
    --warning-button-bg-color: #F18D5D;
    --warning-button-text-color: #FFFFFF;
    --warning-button-bg-hover-color: #DA6D39;
    --warning-button-bg-active-color: #DE5D20;

    /* Button - Disable */
    --disable-button-bg-color: #F5F6F8;
    --disable-button-text-color: #CDD3DB;
    --disable-button-border-color: #CDD3DB;

    /* Risk colors */
    --high-risk: #D13B51;
    --medium-high-risk: #F18D5D;
    --medium-risk: #FDD835;
    --medium-low-risk: #07B951;
    --no-risk: #208835;
    --default-risk: #6F767E;

    /* Borders */
    --primary-border-color: #8A92A8;
    --main-border-color: #EAEBF5;
    --card-border-color: #DEDDF2;

    /*Gradient Colors*/
    --secondary-gradient-color: linear-gradient(180deg, #FFFFFF 0%, #F0F1F3 143.32%);


    /*Other*/
        --popup-screen-bg-color: rgba(232, 232, 240, 0.5);

        /* Nodes */
        --additional-blue-light: #BBDEFB;
        --additional-blue: #2962FF;
        --additional-yellow-light: #FFCC80;
        --additional-yellow: #FFA726;
        --additional-violet-light: #D3BAFF;
        --additional-violet: #7C4DFF;
        --additional-green-light: #B9F6CA;
        --additional-green: #00E676;
        --additional-red-light: #f6b9b9;
        --additional-red: #e60049;

        /* Background Images */
        --auth-background-image: #FFFFFF;

        /* Shadows */
        --shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        --hide-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

        /* Table */
        --odd-row-background-color: #F9FAFB;
}

a{
    color: var(--primary-text-color);
    text-decoration: underline;
}

/* Splitter */
.p-splitter{
    background: var(--secondary-bg-color);
    border-radius: 0;
    color: var(--primary-text-color);
}

.p-splitter-panel{
    overflow: hidden;
}

.p-splitter-panel > div{
    width: 100%
}

.p-splitter .p-splitter-gutter{
    display: none;
    background: var(--sidebar-primary-color);
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle{
    background: var(--sidebar-text-color);
}

/* React Flow */
.react-flow{
    background: var(--primary-bg-color);
}