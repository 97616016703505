.Container{
	overflow: hidden;
}

.Dashboard{
	background: var(--secondary-bg-color);
	overflow: hidden;
}

.DashboardContent{
	height: 100%
}

.FilterWrapper{
	flex-wrap: wrap;
}

.PageContent{
	overflow: hidden;
}

.Title{
	padding-left: 14px;
	position: relative;

	&:before{
		content: '';
		display: block;
		width: 4px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: var(--alternative-primary-color);
		border-radius: 4px;
	}
}

.Grid{
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-column-gap: 24px;
	grid-row-gap: 18px;
}

@media (max-width: 1200px) {
	.Grid{
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: 18px;
		grid-row-gap: 16px;
	}
}

@media (max-width: 768px) {
	.Grid{
		grid-template-columns: minmax(0, 1fr);
		grid-row-gap: 10px;
	}
}