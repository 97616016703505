.Grid{
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-template-rows: repeat(3, 330px);
	grid-gap: 25px;
	height: 100%;

	.FullWidthBlock {
		min-height: 330px;
		grid-column: span 3;
	}

	&[data-type = "double"]{
		grid-template-rows: 150px minmax(0, 1fr);
		grid-template-columns: repeat(2, minmax(0, 1fr));

		.FullWidthBlock{
			grid-column: span 2;
		}
	}
}

.Wrapper {
	background: var(--primary-bg-color);
	border-radius: 8px;
	padding: 0.5rem 1.75rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--card-border-color);
	height: 330px;

	&[data-size="doubled"]{
		grid-row: span 2;
		height: auto;
	}

	&[data-hsize="doubled"]{
		grid-column: span 2;
	}
}

.Content{
	width: 100%;
	padding: 0 1.75rem 30px 0;
}

.Header{
	&[data-border="true"]{
		border-bottom: 1px solid var(--alternative-secondary-bg-color);
		padding-bottom: 0.5rem;
	}

	.Icon{
		margin-right: 0.5rem;

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.HeaderContent{
		height: 32px;
		white-space: nowrap;
	}
}

.Search{
	width: 170px
}

.ContentWrapper{
	position: relative;
	margin-right: -1.75rem;

	&:after{
		content: "";
		position: absolute;
		right: 0;
		bottom: 0;
		height: 30px;
		width: 100%;
		background: var(--hide-gradient);
	}
}

@media (max-width: 1600px) {
	.Grid{
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: auto;

		.FullWidthBlock {
			grid-column: span 2;
		}

		&[data-type = "double"]{
			grid-template-rows: 150px minmax(0, 1fr);
			grid-template-columns: auto;
		}
	}
}