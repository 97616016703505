.Grid{
	display: grid;
	grid-gap: 24px;
	grid-column: span 2;

	&[data-type="doubled"]{
		grid-template-columns: repeat(4, minmax(0, 1fr));
		grid-template-rows: repeat(2, 150px);
	}

	&[data-type="full-width"]{
		grid-template-columns: repeat(6, minmax(0, 1fr));
		grid-template-rows: 150px;
	}
}

.Box{
	background: var(--primary-bg-color);
	border-radius: 8px;
	padding: 1rem;
	overflow: hidden;
	border: 1px solid var(--card-border-color);

	.UserInfoButton{
		padding: 0;
		width: 24px;
	}

	// Clickable
	&[data-clickable="true"] {
		cursor: pointer;
	}

	.Icon{
		:global(.fill){
			fill: var(--alternative-secondary-icons-color);
		}

		&[data-color="danger"]{
			:global(.fill){
				fill: var(--danger-icons-color);
			}
		}

		&[data-color="success"]{
			:global(.fill){
				fill: var(--success-icons-color);
			}
		}

		&[data-color="info"]{
			:global(.fill){
				fill: var(--primary-bg-icons-color);
			}
		}
	}

	.Indicator{
		height: 60px;
		width: 4px;

		// background: var(--default-risk);
		background: var(--alternative-secondary-icons-color);

		&[data-color="no-risk"]{
			background: var(--no-risk);
		}

		&[data-color="medium-risk"]{
			background: var(--medium-risk);
		}

		&[data-color="medium-high-risk"]{
			background: var(--medium-high-risk);
		}

		&[data-color="high-risk"]{
			background: var(--high-risk);
		}
	}

	.ValueWrapper{
		overflow: hidden;
	}

	.Value{
		white-space: nowrap;
	}

	.SubValue{
		white-space: nowrap;
		color: var(--secondary-text-color);
	}
}

.Arrows{
	column-gap: 24px;
}

.Arrow{
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background: var(--secondary-button-bg-color);
	border: 1px solid var(--primary-button-bg-color);
	cursor: pointer;

	:global(.fill){
		fill: var(--primary-bg-icons-color);
	}

	&[data-disable="true"]{
		background: var(--disable-button-bg-color);
		border: 1px solid var(--disable-button-border-color);

		:global(.fill){
			fill: var(--disable-icons-color);
		}
	}

}

@media (max-width: 1600px) {
	.Grid{
		&[data-type="doubled"]{
			grid-column: span 1;
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		&[data-type="full-width"]{
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}
}