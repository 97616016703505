.MenuProfileComponent{
	padding-right: 2rem;
}

.MenuProfileWrapper{
	position: relative;
}

.MenuProfile{
	cursor: pointer;

	.ProfileName,
	.ProfilePosition{
		max-width: 150px;
		white-space: nowrap;
		color: var(--primary-text-color);
	}

	// Profile Icon
	.ProfileIcon{
		padding-right: 0.75rem;
		padding-left: 0.75rem;

		svg{
			width: 30px;
			height: 30px;
		}
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	// Powered By
	.PoweredBy {

		// Powered By Text
		.PoweredByText {
			color: var(--primary-text-color);
		}
	}

	// Arrow Icon
	.ArrowIcon{

		svg{
			width: 24px;
			height: 24px;
		}

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}
}

.DropdownLayer{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
}

.Dropdown{
	position: absolute;
	top: 60px;
	right: 0;
	border: 1px solid var(--alternative-primary-color);
	background: var(--primary-bg-color);
	z-index: 7;
	min-width: 220px;
	border-radius: 10px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.08);
	overflow:hidden;
}

.DropdownLink{
	text-decoration: none;
}

.DropdownItem{
	background: var(--sidebar-primary-color);
	cursor: pointer;

	.DropdownIcon{
		svg{
			flex-shrink: 0;
			width: 16px;
			height: 16px;
		}

		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	.DropdownLabel{
		color: var(--sidebar-text-color);
	}

	&:hover,
	&:active{
		background: var(--sidebar-hover-color);

		:global(.fill){
			fill: var(--primary-bg-icons-color);
		}

		.DropdownLabel{
			color: var(--sidebar-primary-text-color);
		}
	}
}

@media (max-width: 1200px) {
	.MenuProfileComponent{
		padding-right: 0.5rem;
	}

	.MenuProfile{
		.ProfileInfo{
			display: none;
		}

		.ArrowIcon{
			display: none;
		}
	}
}

@media (max-width: 768px){
	.MenuProfileComponent{
		order: 4;
		padding-right: 1.75rem;
	}

	.Dropdown{
		top: 70px;
		min-width: unset;
		width: 100%;
		position: fixed;
	}

	.MenuProfile{
		.ProfileIcon{
			padding-right: 0;
			padding-left: 0;

			svg{
				width: 24px;
				height: 24px;
			}
		}
		.ProfileInfo{
			display: none;
		}

		.ArrowIcon{
			display: none;
		}
	}
}