.Header{
    height: 60px;
    border-bottom: 1px solid var(--card-border-color);

    .ColApproval{
        min-width: 180px;
        border-left: 1px solid var(--card-border-color);
    }
}

.List{
    & > .Item:nth-child(2n){
        background: var(--odd-row-background-color);
    }
}

.Item {
    border-bottom:  1px solid var(--sidebar-hover-color);

    &:last-child{
        border-bottom: none;
    }
}

.Name{
    height: 40px;
}

.Empty{
    width: 100%;
    margin: 10px 0 20px;
    color: var(--secondary-bg-icons-color);
}


.Info{
    padding-left: 20px;
    width: 180px;

    .ApprovedIcon{
        :global(.fill){
            fill: var(--success-button-bg-color);
        }
    }
}