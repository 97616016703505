.Block{
	border-top: 1px solid var(--card-border-color);
}

.Button{
	cursor: pointer;
	border-radius: 4px;
	background-color: var(--primary-bg-color);
	border: 1px solid var(--secondary-button-active-color);
	padding: 0.25rem 0.5rem;
	transition: background-color 0.2s;

	&:hover:not([data-active="true"]){
		background-color: var(--secondary-button-hover-color);
		.Text{
			color: var(--secondary-button-hover-text-color);
		}
	}

	&[data-active="true"]{
		.Text{
			color: var(--alternative-accent-text-color);
		}
	}
}

.Text{
	position: relative;
	transition: color 0.2s;
}