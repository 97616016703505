.Cell {
	height: 100%;
}

.Grid{
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-column-gap: 24px;
	grid-row-gap: 18px;
}

.Diagnose{
	background: var(--primary-bg-color);
	border: 1px solid var(--card-border-color);
	border-radius: 8px;
	padding: 0.5rem 1.75rem;

	.Header{
		cursor: pointer;
		border-bottom: 1px solid var(--alternative-secondary-bg-color);
		flex-wrap: wrap;
	}

	.HeaderTexts{
		width: calc(100% - 250px);
		min-width: 200px;
	}

	.MoreIcon{
		height: 16px;

		:global(.fill){
			fill: var(--alternative-secondary-icons-color);
		}
	}

	.ScrollWrapper{
		margin-right: -20px;
	}

	.ListWrapper{
		min-height: 200px;
	}

	.List{
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		grid-row-gap: 14px;
		grid-template-rows: min-content;
		padding-bottom: 1rem;
	}

	&.NoGuidelines{
		.Header{
			border: none;
		}
	}
}

.TreatmentText{
	&.done{
		text-decoration: line-through;
		color: var(--disable-button-text-color)
	}
}

.IconWrapper{
	& > span{
		display: flex;
		align-items: center;
		justify-content: center;
	}
}