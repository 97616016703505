.Stat{
	width: 100%;
}

.StatList{

}

.StatItem{
	margin-top: 14px;
}

.StatHeader{
	justify-content: space-between;
	padding-bottom: 8px;
	border-bottom: 1px solid var(--main-border-color);
	cursor: pointer;
}

.StatHeaderArrow{
	transition: transform 0.3s;

	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}

	&[data-active="true"]{
		transform: rotate(-180deg);
	}
}

.StatTitleBox{
	align-items: center;

	.StatTitleScore{
		margin-left: 8px;
		color: var(--sidebar-primary-text-color);
	}
}

.StatGrid{
	width: 100%;
	grid-template-columns: minmax(0, 2fr) repeat(3, minmax(0, 1fr));
}

.StatCell{
	padding: 8px 8px;

	.StatCellName{
		display: -webkit-inline-box;
	}
}

.StatCellEmpty{
	padding: 16px 0;
	grid-column: 1/5;
	justify-content: center;

	.StatCellEmptyText{
		color: var(--sidebar-text-color);
	}
}

.MissingCodesWrapper{
	width: 100%;
}

.MissingCodesHeader{
	margin-top: 13px;
	margin-bottom: 7px;
	align-items: center;
}

.MissingCodesEmpty{
	color: var(--sidebar-text-color);
}

.MissingCodesCost{
	margin-left: 10px;
	color: var(--sidebar-primary-text-color);
}

.MissingCodes{
	width: 100%;
	gap: 14px;
	flex-wrap: wrap;
}

.MissingCode{
	padding: 0.5rem;
	border-radius: 8px;
	border: 1px solid var(--secondary-button-border-color);
	width: 140px;

	&.NoImpact{
		background: var(--secondary-bg-color);
	}
}

.MissingCodeHeader{
	padding-bottom: 0.5rem;
	border-bottom: 1px solid var(--secondary-button-border-color);
	margin-bottom: 0.5rem;

	.MissingCodeHeaderText{
		display: -webkit-inline-box;
	}
}

.MissingCodeVer{
	align-items: center;

	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}

	svg{
		margin-right: 7px;
	}
}

.MissingCodeValue{
	margin-top: 0.5rem;

	.MissingCodeValueText{
		display: -webkit-inline-box;
	}
}