.Side{
	flex-grow: 1;
}

.BasicInfo{
	border-bottom: 1px solid var(--card-border-color);
	column-gap: 15px;
}

.ChartBox{
	padding: 15px 0 55px;
	width: 100%;
	border-bottom: 1px solid var(--card-border-color);

	&:last-child{
		border: none;
	}

	.Chart{
		max-width: 100%;
	}
}