.MainMenuWrapper{
    flex-grow: 1;
}

.DropdownLayer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
}

.MainMenuToggle{
    padding-left: 2rem;

    .ArrowIcon{
        :global(.fill){
            fill: var(--secondary-bg-icons-color);
        }
    }
}

.MainMenu {

    // Menu Item
    .MenuItem {
        //margin: 0 1rem 3px 1rem;
        margin: 0 1.5rem;
        height: 52px;
        text-decoration: none;
        color: var(--primary-text-color);
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;

        // Active State
        &.active {
            color: var(--sidebar-primary-text-color);
            border-bottom: 2px solid var(--sidebar-primary-text-color);
            transform: translateY(1px);
        }
    }
}

@media (max-width: 768px){
    .MainMenuWrapper{
        order: 1;
        flex-grow: unset;
    }

    .MainMenu {
        position: fixed;
        top: 70px;
        left: 0;
        border: 1px solid var(--sidebar-primary-text-color);
        background: var(--sidebar-primary-color);
        z-index: 7;
        width: 100%;
        padding: 0 0 10px;

        .MainMenuHeader{
            padding: 0 20px;
            height: 38px;
            border-bottom: 1px solid var(--alternative-secondary-bg-color);

            .HeaderText{
                color: var(--primary-text-color);
            }
        }

        .MenuItem {
            padding: 14px 20px;
            margin: 0;
            height: auto;
            text-decoration: none;
            color: var(--sidebar-text-color);
            transform: none;
            font-size: var(--text-size-x2);
            line-height: var(--text-line-height-x2);
            font-weight: 700;
        }
    }
}