.Grid{
	display: grid;
	grid-template-columns: minmax(0, 1fr) 1px minmax(0, 1fr);
	grid-column-gap: 2rem;
	width: 100%;
}

.Delimeter{
	background: var(--card-border-color);
	height: 100%;
	width: 1px;
}

.HeaderIcon{
	margin-right: 0.5rem;

	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}
}