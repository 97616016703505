.List{
	grid-row-gap: 8px;
}

.Item{
	grid-column-gap: 10px;
	overflow: hidden;
}

.ItemLabel{
	color: var(--primary-text-color);
	min-width: 200px;
}

.ItemValue{
	flex-shrink: 0;
	color: var(--primary-text-color);
	min-width: 60px;
}