.Header {
    background-color: var(--secondary-bg-color);
    border-bottom: 1px solid var(--card-border-color);
    padding: 0.75rem 0 0.75rem;
}

@media (max-width: 768px){
    .Header{
        padding: 0.75rem 0 1rem;
    }
}