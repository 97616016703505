.DiagnosisAndRisk {
    // padding: 1rem;
    // margin-top: 1.75rem;
	// background: var(--primary-bg-color);
	// border: 1px solid var(--card-border-color);
	// border-radius: 8px;
	
	gap: 1rem;
	flex-wrap: wrap;
	justify-content: flex-start;

	// RiskIcon
	.RiskIcon {

		// No-Risk
		&[data-risk = "no-risk"] {
			:global(.fill) {
				fill: var(--no-risk);
			}
		}

		// Medium-Risk
		&[data-risk = "medium-risk"] {
			:global(.fill){
				fill: var(--medium-risk);
			}
		}

		// Medium-High-Risk
		&[data-risk = "medium-high-risk"] {
			:global(.fill){
				fill: var(--medium-high-risk);
			}
		}

		// High-Risk
		&[data-risk = "high-risk"] {
			:global(.fill){
				fill: var(--high-risk);
			}
		}
	}
}