.Menu{
	padding: 1.75rem 1.25rem;
	min-width: 300px;
	background-color: var(--primary-bg-color);
}

.MenuList{
	margin-bottom: 1.75rem;
	border-bottom: 1px solid var(--card-border-color);
}

.MenuItem{
	padding: 1.25rem;
	border-radius: 4px;
	cursor: pointer;
	align-items: center;
	margin-bottom: 5px;

	.MenuItemText{
		color: var(--primary-text-color);
	}

	.MenuItemIcon{
		margin-right: 1.25rem;
		:global(.fill){
			fill: var(--secondary-bg-icons-color);
		}
	}

	&.active,
	&:hover{
		background: var(--sidebar-hover-color);

		.MenuItemText{
			color: var(--sidebar-primary-text-color);
		}

		.MenuItemIcon :global(.fill){
			fill: var(--primary-bg-icons-color);
		}
	}
}

.Widget{
	padding: 1rem;
	gap: 16px;

	background: var(--primary-bg-color);
	border: 1px solid var(--card-border-color);
	border-radius: 8px;
}

.UserIcon{
	width: 32px;
	height: 32px;
	background: var(--sidebar-hover-color);
	border-radius: 50%;

	:global(.fill){
		fill: var(--secondary-bg-icons-color);
	}
}

.UserInfoButton{
	padding: 0;
	width: 24px;
}

.UserInfoList{
	row-gap: 0.5rem;

	.UserInfoParam{
		flex-wrap: wrap;
		
		.UserInfoParamLabel{
			margin-right: 0.5rem;
			white-space:nowrap;
		}

		.UserInfoParamValueText{
			white-space:nowrap;
		}

		.UserInfoParamValueIcon{
			margin-left: 0.5rem;
			cursor: pointer;

			:global(.fill){
				fill: var(--secondary-bg-icons-color);
			}
		}
	}
}